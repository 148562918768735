<template>
  <div>
    <CRow class="p-3">
      <h4 class="text-uppercase">Monthly Billing Report</h4>
    </CRow>
    <CCard>
      <form v-on:submit.prevent="filterBill(search)">
        <CRow class="p-3">
          <CCol cols="2">
            <div class="form-group">
              <select name="status" v-model="search.month" class="form-control">
                <option value>Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
          </CCol>

          <CCol cols="2">
            <div class="form-group">
              <select name="status" v-model="search.year" class="form-control">
                <option value>Select Year</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>
          </CCol>

          <CCol cols="2">
            <div class="form-group">
              <input
                type="text"
                name="ward_no"
                placeholder="Ward No"
                class="form-control"
                v-model="search.ward_no"
              />
            </div>
          </CCol>

          <CCol cols="2">
            <div class="form-group">
              <select
                name="status"
                v-model="search.status"
                class="form-control"
              >
                <option value>Select Status</option>
                <option value="PAID">PAID</option>
                <option value="DUE">DUE</option>
              </select>
            </div>
          </CCol>
          <CCol cols="2">
            <button type="submit" class="btn btn-success w-100">Search</button>
          </CCol>
          <CCol cols="2">
            <a
              @click="exportBill(search)"
              href="javascript:void(0)"
              title="Export to Excel"
            >
               <svg
                class="w-100"
                enable-background="new 0 0 30 30"
                height="30px"
                id="Layer_1"
                version="1.1"
                viewBox="0 0 30 30"
                width="30px"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g>
                  <path
                    clip-rule="evenodd"
                    d="M28.705,7.506l-5.461-6.333l-1.08-1.254H9.262   c-1.732,0-3.133,1.403-3.133,3.136V7.04h1.942L8.07,3.818c0.002-0.975,0.786-1.764,1.758-1.764l11.034-0.01v5.228   c0.002,1.947,1.575,3.523,3.524,3.523h3.819l-0.188,15.081c-0.003,0.97-0.79,1.753-1.759,1.761l-16.57-0.008   c-0.887,0-1.601-0.87-1.605-1.942v-1.277H6.138v1.904c0,1.912,1.282,3.468,2.856,3.468l17.831-0.004   c1.732,0,3.137-1.41,3.137-3.139V8.966L28.705,7.506"
                    fill="green"
                    fill-rule="evenodd"
                  />
                  <path
                    d="M20.223,25.382H0V6.068h20.223V25.382 M1.943,23.438h16.333V8.012H1.943"
                    fill="#08743B"
                  />
                  <polyline
                    fill="#08743B"
                    points="15.73,20.822 12.325,20.822 10.001,17.538 7.561,20.822 4.14,20.822 8.384,15.486 4.957,10.817    8.412,10.817 10.016,13.355 11.726,10.817 15.242,10.817 11.649,15.486 15.73,20.822  "
                  />
                </g>
              </svg>
            </a>
          </CCol>
        </CRow>
      </form>

      <CRow>
        <CCol>
          <div class="table-responsive p-3">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th class="font-weight-bold">Consumer Name</th>
                  <th class="font-weight-bold">Bill Number</th>
                  <th class="font-weight-bold">Meter Serial No</th>
                  <th class="font-weight-bold">Used Unit</th>
                  <th class="font-weight-bold">Total Amount</th>
                  <th class="font-weight-bold">Status</th>
                  <th class="fon-weight-bold">Billing Month</th>
                  <th class="font-weight-bold">Issued At</th>
                  <th class="font-weight-bold">Action</th>
                </tr>
              </thead>

              <tbody v-if="bills.length">
                <tr v-for="bill in bills" :key="bill.id">
                  <td>
                    <router-link
                      class="text-info"
                      :to="'/customer-accounts/' + bill.customer_account_id"
                      >{{ bill.customer_name }}</router-link
                    >
                  </td>
                  <td>{{ bill.bill_number }}</td>
                  <td>{{ bill.meter_serial_no }}</td>
                  <td>{{ bill.used_unit }}</td>
                  <td>{{ bill.total_amount }}</td>
                  <td>{{ bill.status }}</td>
                  <td>{{ months[bill.bill_month] }}, {{ bill.bill_year }}</td>
                  <td>{{ bill.issue_date | moment }}</td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      class="download-bill btn btn-primary btn-sm"
                      @click="downloadBill(bill)"
                      >Download</a
                    >
                    &nbsp;
                    <br />
                    <!-- <router-link :to="'/bills/'+bill.id+'/consumptions'" class="btn btn-info btn-sm">View</router-link> &nbsp;                   -->
                  </td>
                </tr>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="9">
                    <h5 class="text-center">Billing Not Available.</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCol>
      </CRow>

      <CPagination
        v-if="bills.length"
        align="center"
        :pages="rows"
        :active-page.sync="search.currentPage"
        @update:activePage="handlePageChange"
      />
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Bills",
  data: () => {
    return {
      search: {
        ward_no: "",
        time_period: "",
        status: "",
        from: "",
        to: "",
        month: "",
        year: "",
        bill_type: "monthly",
        currentPage: 1,
      },
      dateFormat: "yyyy-MM-dd",
      isMobile: Boolean,
      isMobileOnly: Boolean,
      // bills: [],
      deletingState: false,
      loadingState: false,
      role: {},
      serverErrors: [],
      showDismissibleAlert: false,
      months: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
      // isLoading: false,
      // rows: 1,
      // perPage: 10,
      // currentPage: 1,
       breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Reports", path:"/reports", isActive: false},
      {text:"Billing", path:"/reports/billing", isActive: false}, {text:"Monthly", path:"", isActive: true}]
    };
  },
  components: {},
  computed: { ...mapGetters("Report", ["bills", "rows", "perPage"]) },
  filters: {
    moment: function (date) {
      return moment(date).format("DD MMM, YYYY");
    },
  },
  methods: {
    fromCustomFormatter(date) {
      return moment(date).format("Y-MM-D");
    },
    toCustomFormatter(date) {
      return moment(date).format("Y-MM-DD");
    },

    downloadBill(bill) {
      this.$store.dispatch("Report/downloadBill", bill);
    },

    exportBill(search) {
      this.$store.dispatch("Report/exportBill", search);
    },

    filterBill(search) {
      if (search) {
        this.$store.dispatch("Report/getBills", search);
      }
    },

    handlePageChange(value) {
      this.search.currentPage = value;
      this.$store.dispatch("Report/getBills", this.search);
    },
  },
  mounted() {
    this.$store.dispatch("Report/getBills", this.search);
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}

</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(1) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
